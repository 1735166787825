import React, { forwardRef, useImperativeHandle, useRef } from "react";

import type { BaseBlockFilterModel } from "shared/types/FilterModel";

import type { FilterBlockModel } from "./FilterBlock";
import FilterBlock from "./FilterBlock";

export interface FilterBlocksMethods {
	getSelectedOptions: () => BaseBlockFilterModel[];
	clearFilters: () => void;
}

export interface FilterBlocksProps {
	blocks: FilterBlockModel[];
	defaultFiltersData: BaseBlockFilterModel[];
}

const FilterBlocks = forwardRef<FilterBlocksMethods, FilterBlocksProps>(({ blocks, defaultFiltersData }, ref) => {
	type FilterBlockMethodsHandlers = React.ElementRef<typeof FilterBlock>;
	const filterBlockRef = useRef<(FilterBlockMethodsHandlers | null)[]>([]);

	useImperativeHandle(ref, () => ({
		getSelectedOptions: () => {
			if (filterBlockRef.current.length) {
				return filterBlockRef.current.map(block => ({
					blockId: block!.getBlockId(),
					values: block!.getSelectedOptions(),
					inputText: block!.getInputText()
				}));
			}

			return [];
		},
		clearFilters: () => {
			filterBlockRef.current.forEach(block => block!.clearFilter());
		}
	}));

	return (
		<div className="flex flex-col">
			{blocks.map((block, index) => (
				<div key={`filter-block-${index}`} className="border-b border-gray-100">
					<FilterBlock
						ref={el => (filterBlockRef.current ? (filterBlockRef.current[index] = el) : null)}
						block={block}
						index={index}
						defaultFilterValues={defaultFiltersData.find(x => x.blockId === block.id)?.values}
					/>
				</div>
			))}
		</div>
	);
});

export default FilterBlocks;
