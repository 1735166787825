import React, { memo, useEffect } from "react";

import useLocalStorage from "../../services/localStorage/localStorage";

export interface ErrorFallbackProps {
	error?: any;
}

const ErrorFallback: React.FC<ErrorFallbackProps> = memo(({ error }) => {
	const { setWithExpiry, getWithExpiry } = useLocalStorage();

	// Handles failed lazy loading of a JS/CSS chunk.
	useEffect(() => {
		const chunkFailedMessage = /Loading chunk [\d]+ failed/;
		if (error?.message && chunkFailedMessage.test(error.message)) {
			if (!getWithExpiry("chunk_failed")) {
				setWithExpiry("chunk_failed", "true", 10000);
				window.location.reload();
			}
		}
	}, [error, getWithExpiry, setWithExpiry]);

	return (
		<div>
			<p>Something went wrong.</p>
			<pre>{error?.message}</pre>
		</div>
	);
});

export default ErrorFallback;
