import type { ReactNode } from "react";
import React, { memo, useCallback, useEffect, useRef, useState } from "react";

import clsx from "clsx";

import { useTranslation } from "react-i18next";

import FilterIcon from "assets/icons/outlined/filter.svg?react";

import type { BaseBlockFilterModel } from "shared/types/FilterModel";
import { Button, PopoverBox, SkeletonBase } from "shared/uikit";
import Text from "shared/uikit/Text";
import { ButtonSizesEnum, ButtonVariantsEnum, MenuListPositionOpts, TextVariantsEnum } from "shared/uikit/types";

import type { FilterBlockModel } from "./FilterBlock";
import FilterBlocks from "./FilterBlocks";
import { FilterCardWrapper, FilterContentWrapper } from "./styles";

export interface FilterPopoverProps {
	btnClassName?: string;
	children?: ReactNode;
	filterBlocks?: FilterBlockModel[];
	onApplyFilters?: (blocksValues?: BaseBlockFilterModel[]) => void;
	onClearFilters?: () => void;
	onToggleFilters?: (show: boolean) => void;
	isFilterActive?: boolean;
	showFilters?: boolean;
	smallFilterIcon?: boolean;
	popoverPosition?: MenuListPositionOpts;
	contentPosition?: MenuListPositionOpts;
	filterCardWrapperClassName?: string;
	noPaddingFilterCardWrapper?: boolean;
	childrenWrapperClassName?: string;
	loading?: boolean;
}

const FilterPopover: React.FC<FilterPopoverProps> = memo(
	({
		btnClassName,
		children,
		onApplyFilters,
		onClearFilters,
		onToggleFilters,
		smallFilterIcon,
		showFilters = false,
		isFilterActive,
		popoverPosition = MenuListPositionOpts.TOP_RIGHT,
		contentPosition = MenuListPositionOpts.BOTTOM_RIGHT,
		filterCardWrapperClassName = "",
		childrenWrapperClassName = "",
		filterBlocks = [],
		loading
	}) => {
		const { t } = useTranslation();

		const [showFiltersBox, setShowFiltersBox] = useState(showFilters);
		const [isAnyFilterActive, setIsAnyFilterActive] = useState(isFilterActive);
		const [filtersData, setFiltersData] = useState<BaseBlockFilterModel[]>([]);

		const filterBtnRef = useRef<HTMLButtonElement>(null);
		type FilterBlocksMethodsHandlers = React.ElementRef<typeof FilterBlocks>;
		const filterBlocksRef = useRef<FilterBlocksMethodsHandlers | null>(null);

		useEffect(() => {
			setIsAnyFilterActive(isFilterActive ?? filtersData.some(x => !!x.values?.length));
		}, [isFilterActive, filtersData]);

		const toggleFilters = useCallback(() => {
			setShowFiltersBox(!showFiltersBox);
			onToggleFilters && onToggleFilters(!showFiltersBox);
		}, [showFiltersBox, onToggleFilters]);

		const applyFilters = useCallback(() => {
			toggleFilters();

			const filterBlocksValues = filterBlocksRef.current?.getSelectedOptions
				? filterBlocksRef.current.getSelectedOptions()
				: [];

			onApplyFilters && onApplyFilters(filterBlocksValues);

			setFiltersData(filterBlocksValues);
		}, [toggleFilters, onApplyFilters]);

		const clearFilters = useCallback(() => {
			toggleFilters();

			onClearFilters && onClearFilters();

			filterBlocksRef.current?.clearFilters && filterBlocksRef.current.clearFilters();

			setFiltersData([]);
		}, [toggleFilters, onClearFilters]);

		if (loading) {
			return <SkeletonBase width={42} height={42} fullScale />;
		}

		return (
			<>
				<Button
					ref={filterBtnRef}
					className={clsx(btnClassName)}
					onClick={toggleFilters}
					variant={ButtonVariantsEnum.LINK}
					size={ButtonSizesEnum.MD}
					aria-label="Filter"
					dontFillSvg
					iconButton={{
						icon: (
							<FilterIcon
								className={clsx(
									smallFilterIcon && "w-5 h-5",
									isAnyFilterActive
										? "svg-paths:stroke-primary-500 svg-paths:fill-primary-500"
										: "svg-paths:stroke-gray-400"
								)}
							/>
						)
					}}
				/>
				<PopoverBox
					anchorEl={filterBtnRef.current}
					onClose={toggleFilters}
					show={showFiltersBox}
					customMinWidth={287}
					customMaxWidth={360}
					customMaxHeight={"40vh"}
					popoverPosition={popoverPosition}
					contentPosition={contentPosition}
				>
					<FilterCardWrapper
						noPadding
						className={clsx(filterCardWrapperClassName, "max-h-[inherit] h-full overflow-hidden flex flex-col flex-1")}
					>
						<div className="w-full p-4 border-b border-gray-100">
							<Text variants={TextVariantsEnum.Caption3}>{t("filters")}</Text>
						</div>
						<FilterContentWrapper className="flex flex-1 overflow-scroll">
							{!!children && <div className={clsx(childrenWrapperClassName)}>{children}</div>}
							{!!filterBlocks?.length && (
								<div className={clsx(childrenWrapperClassName, "w-full")}>
									<FilterBlocks ref={filterBlocksRef} blocks={filterBlocks} defaultFiltersData={filtersData} />
								</div>
							)}
						</FilterContentWrapper>
						<div className="grid grid-cols-2 gap-2 p-4">
							<Button variant={ButtonVariantsEnum.FILLED} size={ButtonSizesEnum.XS} onClick={applyFilters}>
								{t("apply")} {t("filters").toLowerCase()}
							</Button>
							<Button variant={ButtonVariantsEnum.OUTLINED} size={ButtonSizesEnum.XS} onClick={clearFilters}>
								{t("reset")} {t("filters").toLowerCase()}
							</Button>
						</div>
					</FilterCardWrapper>
				</PopoverBox>
			</>
		);
	}
);

export default FilterPopover;
