import type { TFunction } from "i18next";

import type { AdvancingTeamRuleModel, MatchMetaModel, MatchModel } from "shared/types";
import { AdvancingRuleType, GroupKind, StageKind } from "shared/types";

export const getMatchRoundName = (match: Partial<MatchModel> | null, t: TFunction) => {
	if (!t || !match) {
		return "";
	}

	const round = match?.meta?.round || 1;
	const isThirdPlace = match?.homeTeamAdvancingRule?.rank === 2 && match?.awayTeamAdvancingRule?.rank === 2;

	// Round count represents count of matches in the current round
	if (round === 1) {
		if (isThirdPlace) {
			return t("match:third_place");
		}
		return t("match:finals");
	}
	if (round === 2) {
		return t("match:semi_finals");
	}
	if (round === 4) {
		return t("match:quarterfinals");
	}
	return t("match:round_of", { num: round * 2 });
};

export const getGroupSymbol = (groupName: string) => {
	if (groupName.includes(" ")) {
		const paths = groupName.split(" ");
		return paths[paths.length - 1];
	}
	return "";
};

export const getLastMatchPositionInRound = (matchPosition: number, totalLevels: number) => {
	const totalGames = Math.pow(2, totalLevels);

	let gamesCount = 0;
	let restGames = totalGames;
	while (gamesCount < matchPosition) {
		restGames /= 2;
		gamesCount += restGames;
	}

	return gamesCount;
};

export const getRunnerUpTeams = (groupsCount: number, advancingTeamsCount: number) => {
	const finalGroupTeams = groupsCount * advancingTeamsCount;
	const val = Math.ceil(Math.log2(finalGroupTeams));
	return Math.pow(2, val);
};

const getSourceMatchPosition = (matchMeta: MatchMetaModel) => {
	const currentMatchPosition = matchMeta.position + 1;
	const currentMatchRound = matchMeta.round;

	let totalGamesCount = 0;
	let level = 0;
	while (totalGamesCount < currentMatchPosition) {
		totalGamesCount = Math.pow(2, level);
		level++;
	}

	const lastMatchPositionInRound = getLastMatchPositionInRound(currentMatchPosition, level - 1);

	return currentMatchPosition - currentMatchRound - (lastMatchPositionInRound - currentMatchPosition);
};

export const getMatchPosition = (matchMeta: MatchMetaModel) => {
	return matchMeta.position + 1;
};

export const getTeamNameByAdvancedRule = ({
	isHome,
	advancingRule,
	match,
	matchGroupName,
	t
}: {
	isHome?: boolean;
	advancingRule: AdvancingTeamRuleModel;
	match?: Partial<MatchModel> | null;
	matchGroupName?: string;
	t: TFunction;
}) => {
	if (match?.meta) {
		if (advancingRule?.sourceMatchId) {
			const sourceRoundMatchPosition = getSourceMatchPosition(match.meta);
			const round = match?.meta?.round || 1;
			const isThirdPlace = match?.homeTeamAdvancingRule?.rank === 2 && match?.awayTeamAdvancingRule?.rank === 2;

			if (round === 1 && isThirdPlace) {
				return `${getMatchRoundName(match, t)} - ${t("match")} ${
					isHome ? sourceRoundMatchPosition - 2 : sourceRoundMatchPosition - 1
				} - ${t("match:loser")}`;
			}

			return `${getMatchRoundName(match, t)} - ${t("match")} ${
				isHome ? sourceRoundMatchPosition - 1 : sourceRoundMatchPosition
			} - ${t("match:winner")}`;
		}

		if (advancingRule?.sourceGroup?.name) {
			// return `${getGroupSymbol(advancingRule.sourceGroup.name)}${advancingRule.visualRank ?? advancingRule.rank}`;
			return getAdvancingRuleName(advancingRule);
		}

		if (
			advancingRule?.type === AdvancingRuleType.GROUP_RUNNER_UP_RANKING ||
			matchGroupName === StageKind.ELIMINATION_RUNNERUP
		) {
			return getAdvancingRuleName(advancingRule);
			// return `${t("match:runner_up")} ${advancingRule.visualRank ?? advancingRule.rank}`;
		}
	}

	return "-";
};

export const getMatchName = (match: Partial<MatchModel> | null, t: TFunction) => {
	if (!t) {
		return "-";
	}

	if (match?.meta) {
		return `${getMatchRoundName(match, t)} - ${t("match")} ${(match?.meta?.position || 0) + 1}`;
	}

	return "-";
};

export const getMatchGroupStageName = (groupName: string | null, t: TFunction) => {
	if (!t) {
		return "";
	}

	let name = "";
	if (groupName) {
		if (groupName === StageKind.ELIMINATION) {
			name = t("match:gold"); // Champions Elimination
		}

		if (groupName === StageKind.ELIMINATION_RUNNERUP) {
			name = t("match:silver"); // Elimination Runners
		}
	}

	return name;
};

export const getMatchGroupName = ({
	match,
	crossoverPlayGroupName,
	round,
	t
}: {
	match: Partial<MatchModel> | null;
	crossoverPlayGroupName?: string | null;
	round: number | null;
	t: TFunction;
}) => {
	if (!t) {
		return "";
	}

	const roundName = round ? getMatchRoundName(match, t) : "";
	const groupName = match?.group?.name || null;
	let name = groupName || "";

	if (groupName) {
		if (groupName === StageKind.ELIMINATION) {
			name = t("match:gold"); // Champions Elimination
		} else if (groupName === StageKind.ELIMINATION_RUNNERUP) {
			name = t("match:silver"); // Elimination Runners
		} else if (crossoverPlayGroupName) {
			name = groupName.localeCompare(crossoverPlayGroupName)
				? `${groupName} / ${crossoverPlayGroupName}`
				: `${crossoverPlayGroupName} / ${groupName}`;
		}
	}

	return name ? `${name} ${roundName ? roundName : ""}` : "-";
};

export const getMatchGroupRoundName = (match: Partial<MatchModel> | null, t: TFunction) => {
	if (!t) {
		return "";
	}

	if (match?.meta?.round) {
		return `${getMatchGroupStageName(match.group?.name || null, t)} ${getMatchRoundName(match, t)}`;
	}

	if (match?.type === GroupKind.GROUP_STAGE) {
		return getMatchGroupName({
			match,
			crossoverPlayGroupName: match.crossoverPlayGroup?.name || null,
			round: match.meta?.round || null,
			t
		});
	}

	return t("match");
};

export const getMatchFormattedIndex = (index: number) => {
	const strIndex = index.toString();

	if (strIndex.length === 3) {
		return index.toString();
	}
	if (strIndex.length === 2) {
		return `0${index}`;
	}
	if (strIndex.length === 1) {
		return `00${index}`;
	}

	return strIndex;
};

// export const getAdvancingRuleName = (ruleInfo?: AdvancingTeamRuleModel) => {
// 	return ruleInfo?.sourceGroup?.name
// 		? `${getGroupSymbol(ruleInfo?.sourceGroup?.name)}${(ruleInfo?.visualRank ?? ruleInfo?.rank) || 1}`
// 		: ruleInfo?.type === AdvancingRuleType.GROUP_RUNNER_UP_RANKING ||
// 			  ruleInfo?.type === AdvancingRuleType.GROUP_DEFAULT_RANKING
// 			? `R${(ruleInfo?.visualRank ?? ruleInfo?.rank) || 1}`
// 			: "?";
// };

export const getAdvancingRuleName = (ruleInfo?: null | AdvancingTeamRuleModel) => {
	return ruleInfo?.sourceGroup?.name
		? `${getGroupSymbol(ruleInfo?.sourceGroup?.name)}${ruleInfo?.rank || 1}`
		: ruleInfo?.type === AdvancingRuleType.GROUP_RUNNER_UP_RANKING ||
			  ruleInfo?.type === AdvancingRuleType.GROUP_DEFAULT_RANKING
			? `R${ruleInfo?.rank || 1}`
			: "?";
};
