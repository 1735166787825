import type { FC } from "react";
import { useCallback, useState } from "react";

import { resendSignUpCode, signIn, signUp } from "@aws-amplify/auth";

import appConfig from "config/appConfig";
import type { SubmitHandler } from "react-hook-form";
import { Controller, useForm } from "react-hook-form";

import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

import GoogleIcon from "assets/icons/colored/google.svg?react";
import AppleIcon from "assets/icons/filled/apple.svg?react";
import { getPaths } from "core/getPaths";

import { OnboardingForm, PasswordInput } from "shared/Components";

import { useAuth, useInvitation, useUser } from "shared/hooks";
import { Button, DividerWithText, Input, Text } from "shared/uikit";
import { ButtonSizesEnum, ButtonVariantsEnum, TextVariantsEnum } from "shared/uikit/types";

import { validateEmail } from "utils/serviceUtils/validators";

interface FormInputs {
	email: string;
	password: string;
}

const { auth } = getPaths();

const LoginRegisterTemplate: FC<{ redirectUrl?: string; isRegister?: boolean }> = ({ redirectUrl, isRegister }) => {
	const { t } = useTranslation();

	const { control, handleSubmit, formState, setError, watch } = useForm<FormInputs>({
		mode: "onChange"
	});

	const { getData: getUserData } = useUser();
	const { tempCredentials } = getUserData();

	const { getData: getInvitationData } = useInvitation();
	const { tempInvitation } = getInvitationData();

	const email = watch("email") || tempCredentials?.email;

	const [submitting, setSubmitting] = useState(false);
	const [showPasswordRules, setShowPasswordRules] = useState(false);
	const [invitationInfo] = useState(tempInvitation);

	const { loginWithGoogle, loginWithApple, logoutPrevUser, generateLink } = useAuth({ invitationInfo, redirectUrl });

	const { errors } = formState;

	const [isValidPassword, setIsValidPassword] = useState(!isRegister);

	const navigate = useNavigate();

	const redirectToVerifyEmail = useCallback(
		async (username?: string) => {
			try {
				username &&
					(await resendSignUpCode({
						username
					}));
				navigate(generateLink(auth.otp.getPath()));
			} catch (e) {
				console.log(e);
			}
		},
		[navigate, generateLink]
	);

	const registerNewUser = async (password: string) => {
		try {
			const res = await signUp({
				username: tempCredentials!.username,
				password,
				options: {
					userAttributes: {
						email: tempCredentials!.email
					},
					autoSignIn: true
				}
			});

			if (res.nextStep.signUpStep === "CONFIRM_SIGN_UP") {
				redirectToVerifyEmail();
			}
		} catch (signUpError: any) {
			const errorCode = signUpError?.code || signUpError?.name;
			if (errorCode === "UsernameExistsException") {
				return loginUser(password);
			}
			if (errorCode === "UserAlreadyAuthenticatedException") {
				logoutPrevUser();
				await loginUser(password);
			}
			console.log({ signUpError });
		}
	};

	const loginUser = async (password: string) => {
		try {
			const res = await signIn({
				username: tempCredentials!.username,
				password: password
			});

			if (res.isSignedIn) {
				return navigate(generateLink(auth.complete.getPath()));
			}

			if (res.nextStep.signInStep === "CONFIRM_SIGN_IN_WITH_TOTP_CODE") {
				navigate(generateLink(auth.otp.getPath()));
			}

			if (res.nextStep.signInStep === "CONFIRM_SIGN_UP") {
				redirectToVerifyEmail(tempCredentials!.username);
			}
		} catch (signInError: any) {
			const exceptionCode = signInError?.code || signInError?.name;
			if (exceptionCode === "UserNotConfirmedException") {
				await redirectToVerifyEmail(tempCredentials!.username);
				return;
			}
			if (exceptionCode === "UserNotFoundException") return registerNewUser(password);
			if (exceptionCode === "NotAuthorizedException")
				setError("password", { message: t("error:incorrect", { field: t("password") }) });

			if (exceptionCode === "UserAlreadyAuthenticatedException") {
				logoutPrevUser();
				await loginUser(password);
			}
		}
	};

	const onSubmit: SubmitHandler<FormInputs> = async data => {
		if (!tempCredentials) return;

		setSubmitting(true);

		try {
			isRegister ? await registerNewUser(data.password) : await loginUser(data.password);
		} catch (error: any) {
			console.error(error);
		} finally {
			setSubmitting(false);
		}
	};
	return (
		<OnboardingForm
			title={isRegister ? t("sign_up") : t("log_in")}
			subtitle={isRegister ? t("please_enter_your_details_to_create_an_account") : t("log_in_with_any_method")}
			onSubmit={handleSubmit(onSubmit)}
		>
			{!isRegister && (
				<>
					<div className="flex flex-col gap-4">
						{appConfig.GLOBAL_CONSTANTS.ALLOW_GOOGLE_AUTH && (
							<Button
								size={ButtonSizesEnum.LG}
								variant={ButtonVariantsEnum.OUTLINED}
								startIcon={<GoogleIcon />}
								onClick={loginWithGoogle}
								id="continue-with-google"
							>
								{t("continue_with")} Google
							</Button>
						)}
						{appConfig.GLOBAL_CONSTANTS.ALLOW_APPLE_AUTH && (
							<Button
								size={ButtonSizesEnum.LG}
								variant={ButtonVariantsEnum.OUTLINED}
								startIcon={<AppleIcon />}
								onClick={loginWithApple}
								id="continue-with-apple"
							>
								{t("continue_with")} Apple
							</Button>
						)}
					</div>
					{(appConfig.GLOBAL_CONSTANTS.ALLOW_GOOGLE_AUTH || appConfig.GLOBAL_CONSTANTS.ALLOW_APPLE_AUTH) && (
						<DividerWithText text="OR" className="my-6" />
					)}
				</>
			)}
			<Controller
				name="email"
				control={control}
				rules={{
					required: t("error:is_required", { field: t("email") }),
					validate: email => validateEmail(email) || t("error:not_valid", { field: t("email") })
				}}
				defaultValue={tempCredentials?.email}
				render={({ field: { value } }) => (
					<Input
						size={ButtonSizesEnum.UNDER_MD}
						placeholder={t("email")}
						value={value ? value.toLowerCase() : value}
						onChange={() => {}}
						error={!!errors.email?.message}
						errorText={errors.email?.message}
						disabled
					/>
				)}
			/>
			<Controller
				name="password"
				control={control}
				rules={{
					required: t("error:is_required", { field: t("password") })
				}}
				render={({ field: { onChange, value } }) => (
					<div className="relative mt-4">
						<PasswordInput
							onChange={onChange}
							value={value}
							error={errors.password?.message}
							setIsValidPassword={setIsValidPassword}
							placeholder={t("password")}
							size={ButtonSizesEnum.UNDER_MD}
							includeRules={isRegister && showPasswordRules}
							onFocus={() => setShowPasswordRules(true)}
							onBlur={() => setShowPasswordRules(false)}
						/>
					</div>
				)}
			/>
			{!isRegister && (
				<div className="flex justify-end mt-2">
					<Text variants={TextVariantsEnum.BodyMedium} className="text-primary-600">
						<Link to={`${auth.forgotPassword.getPath()}?email=${encodeURIComponent(email || "")}`}>
							{t("forgot_password")}
						</Link>
					</Text>
				</div>
			)}
			<Button
				className="mt-6"
				size={ButtonSizesEnum.LG}
				variant={ButtonVariantsEnum.FILLED}
				id={"continue-with-email-btn"}
				disabled={!!Object.keys(errors).length || submitting || !isValidPassword}
				type="submit"
				loading={submitting}
			>
				{t("contin")}
			</Button>
		</OnboardingForm>
	);
};

export default LoginRegisterTemplate;
