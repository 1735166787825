import type { FC, ReactNode } from "react";
import React, { useCallback, useEffect } from "react";

import { Accordion, ButtonBase } from "@mui/material";

import clsx from "clsx";

import ChevronIcon from "assets/icons/outlined/chevron-right.svg?react";

import { AccordionBoxDetails, AccordionBoxSummary } from "./style";

import Text from "../Text";
import { TextVariantsEnum } from "../types";

export interface AccordionBoxProps {
	expanded?: boolean;
	summaryTitle?: string | ReactNode;
	selectedValuesCount?: number;
	showActiveCircle?: boolean;
	summary?: ReactNode;
	hideSummaryArrow?: boolean;
	details: ReactNode;
	onExpand?: (expanded: boolean) => void;
}

const AccordionBox: FC<AccordionBoxProps> = ({
	expanded,
	summary,
	summaryTitle,
	selectedValuesCount,
	showActiveCircle,
	hideSummaryArrow,
	details,
	onExpand
}) => {
	const [isExpanded, setIsExpanded] = React.useState(!!expanded);

	useEffect(() => {
		setIsExpanded(!!expanded);
	}, [expanded]);

	const handleExpand = useCallback(() => {
		setIsExpanded(!isExpanded);
		onExpand && onExpand(!isExpanded);
	}, [isExpanded, onExpand]);

	return (
		<Accordion
			disableGutters
			elevation={0}
			square
			expanded={isExpanded}
			onChange={handleExpand}
			className="bg-transparent before:hidden"
		>
			<AccordionBoxSummary>
				{summary ?? (
					<div className="w-full flex items-center justify-between gap-2 py-3 px-4">
						<div className="flex items-center gap-2">
							{typeof summaryTitle === "string" ? (
								<Text variants={TextVariantsEnum.Numbers14} className="text-hint">
									{summaryTitle}
								</Text>
							) : (
								summaryTitle
							)}
							{(!!selectedValuesCount || showActiveCircle) && (
								<div className="min-w-6 h-6 flex items-center justify-center py-1 px-2 bg-primary-500 rounded-full overflow-hidden">
									<Text variants={TextVariantsEnum.BodySmall} className="text-white">
										{selectedValuesCount && selectedValuesCount > 0 ? selectedValuesCount : ""}
									</Text>
								</div>
							)}
						</div>
						{!hideSummaryArrow && (
							<ButtonBase className="w-6 h-6 rounded-full overflow-hidden bg-gray-50">
								<ChevronIcon
									className={clsx(
										"w-4 h-4 transition-transform svg-paths:stroke-gray-300",
										isExpanded ? "-rotate-90" : "rotate-90"
									)}
								/>
							</ButtonBase>
						)}
					</div>
				)}
			</AccordionBoxSummary>
			<AccordionBoxDetails>{details}</AccordionBoxDetails>
		</Accordion>
	);
};

export default AccordionBox;
