import type { FC } from "react";
import { useCallback, useMemo } from "react";

import clsx from "clsx";

import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import HomeIcon from "assets/icons/outlined/home.svg?react";
import { getPaths } from "core/getPaths";

import { useMatch } from "shared/hooks";
import type { AdvancingTeamRuleModel, FullTeamModel, MatchModel } from "shared/types";
import { ImageSizesEnum, Img, SkeletonBase, Text } from "shared/uikit";
import { TextVariantsEnum } from "shared/uikit/types";
import { getTeamNameByAdvancedRule } from "utils/getMatchInfo";

const { team: teamRoutes } = getPaths();

const Match: FC<{ loading: boolean; match?: MatchModel }> = ({ loading, match }) => {
	const { t } = useTranslation();

	const { getMatchData } = useMatch();
	const { matchInfo } = getMatchData();

	const getMatchDate = useCallback((date?: string | null) => {
		if (date) {
			const luxonDate = DateTime.fromISO(date);
			return luxonDate.isValid ? luxonDate.toFormat("ccc, d LLL") : date;
		}
		return "";
	}, []);

	const getMatchTime = useCallback((date?: string | null) => {
		if (date) {
			const luxonDate = DateTime.fromISO(date);
			return luxonDate.isValid ? luxonDate.toFormat("h:mm a") : date;
		}
		return "";
	}, []);

	const renderTeam = useCallback(
		({
			isHome,
			team,
			advancingRule,
			match,
			matchGroupName
		}: {
			isHome: boolean;
			team?: FullTeamModel | null;
			advancingRule?: AdvancingTeamRuleModel | null;
			match?: Partial<MatchModel> | null;
			matchGroupName?: string;
		}) => {
			if (loading) {
				return (
					<div className={clsx("flex items-center flex-1 gap-4 justify-end", !isHome && "flex-row-reverse")}>
						<div>
							<div className="flex items-center gap-2">
								{isHome && <HomeIcon className="w-4 h-4 fill-gray-300" />}
								<SkeletonBase width={160} height={16} fullScale />
							</div>
							<div className={clsx("flex items-center gap-2 mt-1", isHome && "flex-row-reverse")}>
								<SkeletonBase width={80} height={14} fullScale />
							</div>
						</div>
						<SkeletonBase width={40} height={40} variant="circular" fullScale />
					</div>
				);
			}

			if (team) {
				return (
					<Link
						to={`${teamRoutes.team.getPath()}/${team?.id}`}
						className={clsx("flex items-center flex-1 gap-4 justify-end", !isHome && "flex-row-reverse")}
					>
						<div>
							<div className="flex items-center gap-2">
								{isHome && <HomeIcon className="w-4 h-4 fill-gray-300" />}
								<Text variants={TextVariantsEnum.H7}>{team?.name}</Text>
							</div>
							<div className={clsx("flex items-center gap-2 mt-1", isHome && "flex-row-reverse")}>
								<Text variants={TextVariantsEnum.Caption3} className="text-hint">
									{team?.club?.name}
								</Text>
							</div>
						</div>
						<Img src={team?.club?.logo} alt={team?.name} size={ImageSizesEnum.Small40} circle />
					</Link>
				);
			}

			if (advancingRule) {
				const teamName = getTeamNameByAdvancedRule({
					isHome,
					advancingRule,
					match,
					matchGroupName,
					t
				});

				return (
					<div className={clsx("flex items-center gap-4", isHome && "flex-row-reverse")}>
						<div className="relative">
							<Img src={null} alt="?" size={ImageSizesEnum.Small64} circle />
						</div>
						<div className={clsx("flex flex-col gap-1", isHome && "text-right")}>
							<div className="flex items-center gap-2">
								{isHome && <HomeIcon className="w-4 h-4 svg-paths:fill-gray-300" />}
								<Text variants={TextVariantsEnum.Caption3}>{teamName}</Text>
							</div>
						</div>
					</div>
				);
			}

			return null;
		},
		[loading, t]
	);

	const showScore = useMemo(
		() =>
			!!matchInfo?.result &&
			typeof matchInfo?.homeTeam?.score === "number" &&
			typeof matchInfo.awayTeam?.score === "number",
		[matchInfo]
	);

	return (
		<div className="w-full">
			<div className="pt-4">
				<div className="flex align-center justify-center gap-7">
					{renderTeam({
						team: match?.homeTeam,
						isHome: true,
						advancingRule: match?.homeTeamAdvancingRule,
						match,
						matchGroupName: match?.group?.name
					})}
					<div className="flex flex-col items-center justify-center">
						{/* TODO: either render time of match or result of match when API is ready */}

						{loading ? (
							<>
								<SkeletonBase width={60} height={18} fullScale />
								<SkeletonBase width={90} height={14} fullScale className="mt-1" />
							</>
						) : (
							<>
								{showScore ? (
									<Text variants={TextVariantsEnum.H4}>
										{matchInfo!.homeTeam!.score} : {matchInfo!.awayTeam!.score}
									</Text>
								) : (
									<Text variants={TextVariantsEnum.H8}>{getMatchTime(match?.playedAt)}</Text>
								)}
								<Text variants={TextVariantsEnum.H7} className="text-hint">
									{getMatchDate(match?.playedAt || match?.date) || "Unscheduled"}
								</Text>
							</>
						)}
					</div>
					{renderTeam({
						team: match?.awayTeam,
						isHome: false,
						advancingRule: match?.awayTeamAdvancingRule,
						match,
						matchGroupName: match?.group?.name
					})}
				</div>
			</div>
		</div>
	);
};

export default Match;
