import type { FC } from "react";
import React, { useCallback, useMemo, useState } from "react";

import { Link } from "@mui/material";

import clsx from "clsx";
import { DateTime } from "luxon";

import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";

import HomeIcon from "assets/icons/filled/home.svg?react";
import LocationIcon from "assets/icons/filled/location.svg?react";

import { CardWrapper, Chip, TimeBase } from "shared/Components";
import { ChipStyles } from "shared/Components/Chip";
import { useEvent } from "shared/hooks";
import type { AdvancingTeamRuleModel, EventModel, FullTeamModel, MatchModel, NeverProps } from "shared/types";
import { RSVPStatusEnum } from "shared/types";

import { CalendarEventEnum } from "shared/types/MatchStatusEnum";
import { Button, ImageSizesEnum, Img, Text } from "shared/uikit";
import { ButtonSizesEnum, TextVariantsEnum } from "shared/uikit/types";

import { getMatchGroupRoundName, getTeamNameByAdvancedRule } from "utils/getMatchInfo";

import SkeletonEventCard from "./SkeletonEventCard";

import EventType from "../EventType";
// import RSVPStatus from "../RSVPStatus";

interface EventProps {
	event: EventModel | MatchModel;
	matchPageUrl: string;
	loading?: never;
}

interface SkeletonProps extends NeverProps<EventProps> {
	loading?: boolean;
}

type Props = EventProps | SkeletonProps;

const EventCard: FC<Props> = ({ event, matchPageUrl, loading }) => {
	const { t } = useTranslation();

	const { updateEventRSVPStatus } = useEvent();

	const [updatingRSVP, setUpdatingRSVP] = useState<RSVPStatusEnum | null>(null);

	const RSVPStatus = useMemo(() => (event as EventModel)?.soccer?.myAttendanceStatus || null, [event]);

	const renderTeam = useCallback(
		({
			isHome,
			team,
			advancingRule,
			match,
			matchGroupName
		}: {
			isHome: boolean;
			team?: FullTeamModel | null;
			advancingRule?: AdvancingTeamRuleModel | null;
			match?: Partial<MatchModel> | null;
			matchGroupName?: string;
		}) => {
			if (team || !advancingRule) {
				return (
					<div className={clsx("flex items-center gap-4", isHome && "flex-row-reverse")}>
						<div className="relative">
							<Img src={team?.logo || team?.club?.logo} alt={team?.name || "-"} size={ImageSizesEnum.Small64} circle />
						</div>
						<div className={clsx("flex flex-col gap-1", isHome && "text-right")}>
							<div className="flex items-center gap-2">
								{isHome && <HomeIcon className="w-4 h-4 svg-paths:fill-gray-300" />}
								<Text variants={TextVariantsEnum.Caption3}>{team?.name || "-"}</Text>
							</div>
							{team?.club?.name && (
								<Text variants={TextVariantsEnum.Caption2} className="text-hint">
									{team.club?.name}
								</Text>
							)}
						</div>
					</div>
				);
			}

			if (advancingRule) {
				const teamName = getTeamNameByAdvancedRule({
					isHome,
					advancingRule,
					match,
					matchGroupName,
					t
				});

				return (
					<div className={clsx("flex items-center gap-4", isHome && "flex-row-reverse")}>
						<div className="relative">
							<Img src={null} alt="?" size={ImageSizesEnum.Small64} circle />
						</div>
						<div className={clsx("flex flex-col gap-1", isHome && "text-right")}>
							<div className="flex items-center gap-2">
								{isHome && <HomeIcon className="w-4 h-4 svg-paths:fill-gray-300" />}
								<Text variants={TextVariantsEnum.Caption3}>{teamName}</Text>
							</div>
						</div>
					</div>
				);
			}

			return null;
		},
		[t]
	);

	const handleRSVPStatus = useCallback(
		async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, status: RSVPStatusEnum) => {
			if ((event as EventModel)?.soccer?.match?.id) {
				e.preventDefault();

				setUpdatingRSVP(status);
				await updateEventRSVPStatus((event as EventModel).soccer.match!.id!, status);
				setUpdatingRSVP(null);
			}
		},
		[event, updateEventRSVPStatus]
	);

	const eventIsMatchModel = (event?: EventModel | MatchModel): event is MatchModel => {
		return !!event && !!(event as MatchModel).id;
	};

	const renderMatch = useMemo(() => {
		if (!event) return;

		const matchInfo = (eventIsMatchModel(event) ? event : event.soccer.match) as Partial<MatchModel>;

		const facilityName = matchInfo?.field?.facility?.name || matchInfo?.facility?.name;
		const eventInfo = {
			key: matchInfo.id || "",
			id: matchInfo.id || "",
			homeTeam: matchInfo.homeTeam,
			awayTeam: matchInfo.awayTeam,
			homeTeamAdvancingRule: matchInfo?.homeTeamAdvancingRule,
			awayTeamAdvancingRule: matchInfo?.awayTeamAdvancingRule,
			meta: matchInfo?.meta,
			group: matchInfo?.group,
			division: matchInfo?.division,
			date: matchInfo.playedAt || matchInfo.date,
			address:
				matchInfo?.field?.location?.address ||
				matchInfo.field?.facility?.location?.address ||
				matchInfo.facility?.location?.address,
			facilityField: `${facilityName ? facilityName : ""} ${
				matchInfo?.field?.name ? `/ ${matchInfo?.field?.name}` : ""
			}`,
			noTime: !matchInfo.playedAt,
			showScore:
				!!matchInfo.result &&
				typeof matchInfo?.homeTeam?.score === "number" &&
				typeof matchInfo.awayTeam?.score === "number",
			type: matchInfo?.type
		};

		return (
			<Link
				to={`${matchPageUrl}/${eventInfo.id}`}
				key={eventInfo.key}
				className="relative no-underline block w-full"
				component={RouterLink}
				id={`event-id-${eventInfo.key}`}
			>
				<CardWrapper>
					{eventInfo.division && (
						<div className="flex items-center justify-between">
							<div className="w-4/12 flex">
								<Chip text={getMatchGroupRoundName(eventInfo, t)} chipStyle={ChipStyles.LIGHT_FILL} />
							</div>
							<div className="w-4/12 flex justify-center">
								{eventInfo?.group?.competition?.name && (
									<Chip text={eventInfo.group.competition.name} chipStyle={ChipStyles.LIGHT_FILL} />
								)}
							</div>
							<div className="w-4/12 flex justify-end">
								{eventInfo?.division?.name && <Chip text={eventInfo.division.name} chipStyle={ChipStyles.LIGHT_FILL} />}
							</div>
						</div>
					)}
					<div
						className={clsx(
							"px-4 py-3",
							(eventInfo?.facilityField || eventInfo?.address) && "border-b border-gray-100"
						)}
					>
						{!eventInfo.division && (
							<EventType type={(event as MatchModel)?.group === null ? CalendarEventEnum.FRIENDLY_MATCH : undefined} />
						)}
						<div className="flex justify-between items-start mt-2 relative">
							<div className="w-2/5 flex justify-end">
								{renderTeam({
									team: eventInfo?.homeTeam,
									isHome: true,
									advancingRule: eventInfo?.homeTeamAdvancingRule,
									match: eventInfo,
									matchGroupName: eventInfo.group?.name
								})}
							</div>
							<div
								className={clsx(
									"flex-1 mt-3 text-center",
									eventInfo.noTime ? "flex flex-col items-center justify-center h-full" : "top-1"
								)}
							>
								{eventInfo.showScore && (
									<Text variants={TextVariantsEnum.H4}>
										{eventInfo.homeTeam!.score} : {eventInfo.awayTeam!.score}
									</Text>
								)}
								<Text variants={TextVariantsEnum.BodyMedium} className="text-hint">
									{eventInfo.date
										? DateTime.fromJSDate(new Date(eventInfo.date)).toFormat("ccc, dd LLL")
										: t("match:unscheduled")}
								</Text>
								{!eventInfo.showScore && eventInfo.date && !eventInfo.noTime && (
									<Text variants={TextVariantsEnum.Caption3} className="text-center">
										<TimeBase time={eventInfo.date} />
									</Text>
								)}
							</div>
							<div className="w-2/5 flex">
								{renderTeam({
									team: eventInfo?.awayTeam,
									isHome: false,
									advancingRule: eventInfo?.awayTeamAdvancingRule,
									match: eventInfo,
									matchGroupName: eventInfo.group?.name
								})}
							</div>
						</div>
					</div>
					<hr className="border-gray-100" />
					<div className="flex items-center justify-between p-4 pb-3">
						<div className="flex flex-col gap-3">
							<div className="flex items-center">
								<LocationIcon className="w-4 h-4 mr-1 svg-paths:fill-primary-500" />
								<Text variants={TextVariantsEnum.BodySmall} className="text-hint">
									{eventInfo?.facilityField || eventInfo?.address || "-"}
								</Text>
							</div>
							{/*<RSVPStatus placeholder />*/}
						</div>

						<div className="flex items-center gap-2">
							<Button
								size={ButtonSizesEnum.SM}
								onClick={e => handleRSVPStatus(e, RSVPStatusEnum.DECLINE)}
								secondary={RSVPStatus !== RSVPStatusEnum.DECLINE}
								disabled={!!updatingRSVP}
								loading={updatingRSVP === RSVPStatusEnum.DECLINE}
							>
								{t("match:decline")}
							</Button>
							<Button
								size={ButtonSizesEnum.SM}
								onClick={e => handleRSVPStatus(e, RSVPStatusEnum.INTRESTED)}
								secondary={RSVPStatus !== RSVPStatusEnum.INTRESTED}
								disabled={!!updatingRSVP}
								loading={updatingRSVP === RSVPStatusEnum.INTRESTED}
							>
								{t("match:interested")}
							</Button>
							<Button
								size={ButtonSizesEnum.SM}
								onClick={e => handleRSVPStatus(e, RSVPStatusEnum.GOING)}
								secondary={RSVPStatus !== RSVPStatusEnum.GOING}
								disabled={!!updatingRSVP}
								loading={updatingRSVP === RSVPStatusEnum.GOING}
							>
								{t("match:going")}
							</Button>
						</div>
					</div>
				</CardWrapper>
			</Link>
		);
	}, [RSVPStatus, event, handleRSVPStatus, matchPageUrl, renderTeam, updatingRSVP, t]);

	return !event || loading ? <SkeletonEventCard /> : renderMatch || null;
};

export default EventCard;
