import { styled } from "@mui/material";

import { CardWrapper } from "shared/Components";

export const FilterCardWrapper = styled(CardWrapper)`
	box-shadow: 0 0 12px rgba(0, 0, 0, 0.1);

	border: 1px solid rgba(220, 229, 245, 0.5);
`;

export const FilterGrid = styled("div")`
	grid-template-columns: 5fr 5fr 2fr;
`;

export const FilterContentWrapper = styled("div")`
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: unset; /* Firefox */

	&::-webkit-scrollbar {
		visibility: hidden;
		display: none;
	}
`;
