import type { FC } from "react";
import React, { Suspense, lazy } from "react";

import { useTranslation } from "react-i18next";

import type { PostAttachmentModel } from "shared/types";
import { AttachmentKind } from "shared/types";
import { ImageSizesEnum, Img, Text } from "shared/uikit";

import { TextVariantsEnum } from "shared/uikit/types";

const PollSlide = lazy(() => import("./PollSlide"));
const VideoSlide = lazy(() => import("./VideoSlide"));
const EntitySlide = lazy(() => import("./EntitySlide"));

interface AttachmentSlideProps {
	attachment: PostAttachmentModel;
}

const AttachmentSlide: FC<AttachmentSlideProps> = ({ attachment }) => {
	const { t } = useTranslation();

	return (
		<div className="w-full h-full">
			{attachment.kind === AttachmentKind.ENTITY && <EntitySlide {...attachment} />}
			{attachment.kind === AttachmentKind.IMAGE && (
				<Img
					src={{
						id: "",
						variants: attachment!.image?.variants
					}}
					alt="post-attachment-image"
					size={ImageSizesEnum.FULL}
				/>
			)}
			{attachment.kind === AttachmentKind.GIF && (
				<Img src={attachment.gif} alt="post-attachment-gif" containWithBg size={ImageSizesEnum.FULL} />
			)}
			{attachment.kind === AttachmentKind.VIDEO && (
				<Suspense>
					<VideoSlide src={attachment.video} />
				</Suspense>
			)}
			{attachment.kind === AttachmentKind.POLL && (
				<>
					{!!attachment?.poll ? (
						<Suspense>
							<PollSlide poll={attachment.poll!} />
						</Suspense>
					) : (
						<div className="w-full h-full flex items-center justify-center">
							<Text variants={TextVariantsEnum.BodyLarge} className="text-hint">
								{t("not_supported_content")}
							</Text>
						</div>
					)}
				</>
			)}
		</div>
	);
};

export default AttachmentSlide;
